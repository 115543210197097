<template>
  <UsersLayout>
    <v-simple-table class="my-table" :dense="!isLarge">
    <template v-slot:default>
    <UsersHeader
      @filter="onFilter"
    />
    <tbody name="fade" is="transition-group">
      <UserRow
        v-for="(user, index) in users"
        :key="user.uuid"
        :user="user"
        :index="index"
        @delete="deleteUser"
        @toggleRow="toggleRow"
        :class="{'active-row' : activeRow == user.uuid}"
      />
    </tbody>
    </template>
  </v-simple-table>
  <div v-if="!loading && users.length === 0" class="text-center">
     {{$t('table.noData')}}
    </div>
        <v-progress-linear
      v-if="isLoadingFilter"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-observer="{nextPage:nextPage}" class="observer" v-if="!loadMoreDisabled && !loading"></div>
    <ConfirmDeleteDialog
      v-if="canDelete"
      :visible="confirmDelete"
      :uuid="uuidToDelete"
      @close="confirmDelete = false"
      @delete="deleteUser"
    />
    <EditUserDialog
      v-if="editUser && canEdit"
      @close="editUserDialog = false; editUser = ''"
      :user-item="editUser"
      :visible="editUserDialog"
    />
  </UsersLayout>
</template>

<script>
import UsersLayout from './UsersLayout.vue';
import loader from '../../mixins/loader';
import notifications from '../../mixins/notifications';
import usersService from '../../services/request/users/usersService';
import ConfirmDeleteDialog from '../../components/dialog/ConfirmDeleteDialog.vue';
import EditUserDialog from '../../components/users/EditUserDialog.vue';
import usersMixin from './usersMixin';
import user from '../../mixins/user';
import UserRow from './UserRow.vue';
import paginationMixin from '../../mixins/paginationMixin';
import UsersHeader from './UsersHeader.vue';
import SizeUi from "../../mixins/SizeUi";

export default {
  name: 'Admins',
  components: {
    ConfirmDeleteDialog,
    EditUserDialog,
    UsersLayout,
    UserRow,
    UsersHeader,
  },
  mixins: [loader, notifications, usersMixin, user, paginationMixin,SizeUi],
  data: () => ({
    users: [],
    loadMoreDisabled: false,
    activeRow:'',
    isLoadingFilter:false,
    loading:false

  }),
  methods: {
    toggleRow(e){
    this.activeRow = e
    },
    async getUsersList(props = {},isLoadingLine,updated) {
      try {
        this.loading = true
        if(isLoadingLine){
          this.isLoadingFilter = true
        }else{
          this.setLoading(true);
        }
        this.loading = true
        const params = {}
        params.offset = updated ? 0 : (this.page - 1) * this.perPage
        params.limit = updated ? this.largeLimit : this.perPage

        const newItems = await usersService.getAdminsList({
          limit: this.perPage,
          offset: (this.page - 1) * this.perPage,
          ...props,
          order: 'DESC',
          orderby: 'time_created',
          ...params
        });
        if (newItems) {
          this.users.push(...newItems);
          this.users = this.getUniqueUsers(this.users);
          this.loadMoreDisabled = newItems.length < this.perPage;
        }else{
          this.loadMoreDisabled = true

        }
        if(isLoadingLine){
          this.isLoadingFilter = false
        }else{
          this.setLoading(false);
        }
        this.loading = false
      } catch (e) {
         this.loading = false
           if(isLoadingLine){
          this.isLoadingFilter = false
        }else{
          this.setLoading(false);
        }
        this.setErrorNotification(e);
      }
    },
  },
};
</script>

<style scoped>

</style>
